import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";

export const TypesOfAMortgagePageTemplate = ({
  image,
  title,
  subtitle,
  types,
  helmet,
}) => (
  <div className="content">
    {helmet || ""}

    <div
      className="margin-top-0"
      style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
        backgroundPosition: `center`,
        padding: "5rem",
      }}
    >
      <div className="section">
        <div
          className="container has-text-centered"
          style={{ marginTop: "7rem" }}
        >
          <h2 className="has-text-weight-bold is-size-1 has-text-white">
            {title}
          </h2>

          <h4 className="has-text-weight-semibold is-size-4 has-text-white">
            {subtitle}
          </h4>
          <hr className="has-background-dark" />
          <div>
            <div className="columns is-multiline is-centered">
              {Array.isArray(types)
                ? types.map((type, idx) => (
                    <div key={type.abvtitle} className="column is-3">
                      <a
                        href={`#${type.abvtitle}`}
                        className="button is-bold is-outlined is-white is-rounded is-fullwidth is-large"
                      >
                        {type.abvtitle}
                      </a>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </div>
    </div>

    {Array.isArray(types)
      ? types.map((type, idx) => (
          <section
            id={type.abvtitle}
            key={`${idx}-type`}
            style={{ borderTop: "6px solid #0078BE" }}
          >
            <div
              className="margin-top-0"
              style={{
                backgroundImage: `url(${
                  !!type.banner.childImageSharp
                    ? type.banner.childImageSharp.fluid.src
                    : type.banner
                })`,
                minHeight: 550,
                backgroundPosition: `center`,
                padding: "5rem",
              }}
            >
              <div className="section has-text-centered">
                <div className="container">
                  <h1
                    className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
                    style={{
                      color: "white",
                      padding: "0.25em",
                    }}
                  >
                    Step {idx + 1}: {type.title}
                  </h1>
                  <hr className={"has-background-dark"} />
                  <h6 className="has-text-white has-text-centered">
                    {type.description}
                  </h6>
                </div>
              </div>
              <div className="section">
                <div className="container">
                  <div className="columns is-multiline">
                    {Array.isArray(type.info)
                      ? type.info.map((info, infoIdx) => (
                          <div
                            key={`${idx}-${infoIdx}`}
                            className="column is-half has-text-white has-text-centered"
                          >
                            <h3 className="title has-text-weight-bold has-text-white">
                              {info.title}
                            </h3>
                            <p>{info.description}</p>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </section>
        ))
      : null}
  </div>
);

TypesOfAMortgagePageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  types: PropTypes.array,
};

const TypesOfAMortgagePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <TypesOfAMortgagePageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        subtitle={frontmatter.subtitle}
        types={frontmatter.types}
        helmet={
          <Helmet titleTemplate={`Types Of A Mortgage | Get Started`}>
            <title>{`Types Of A Mortgage`}</title>
            <meta name="description" content={`Types Of A Mortgage`} />
          </Helmet>
        }
      />
    </Layout>
  );
};

TypesOfAMortgagePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default TypesOfAMortgagePage;

export const ProcessOfAMortgagePageQuery = graphql`
  query TypesOfAMortgagePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        subtitle
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        types {
          title
          abvtitle
          title
          description
          banner {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          info {
            title
            description
          }
        }
      }
    }
  }
`;
